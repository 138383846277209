import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption";

import One from "../../../content/assets/branding-irons/1.jpg";
import Two from "../../../content/assets/branding-irons/2.jpg";
import Three from "../../../content/assets/branding-irons/3.jpg";

const BrandingIrons = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const brandingIrons = [
    {
      src: One,
      width: 1,
      height: 1.7,
      caption: `One of 2 Branding Iron fabricated for the Polo Ralph Lauren Company. This one has letters 2 1/8 inches tall.`
    },
    {
      src: Two,
      width: 1,
      height: 1,
      caption: `Branding Irons made the old way with forge welds, mortice & tenon, or rivets.`
    },
    {
      src: Three,
      width: 2,
      height: 1,
      caption: ``
    },
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Branding Irons</h1>
        <Gallery photos={brandingIrons} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={brandingIrons.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default BrandingIrons
